import RequestClient from "@/services/request-client";
import Echo from "laravel-echo";

export default {
    data() {
        return {
            echo: null,
            pusher: null,
        };
    },
    created() {
        this.pusher = require("pusher-js");

        this.echo = new Echo({
            broadcaster: "pusher",
            key: process.env.VUE_APP_PUSHER_APP_KEY,
            cluster: process.env.VUE_APP_PUSHER_APP_CLUSTER,
            forceTLS: true,
            // eslint-disable-next-line
            authorizer: (channel, options) => {
                return {
                    authorize: (socketId, callback) => {
                        RequestClient.post(`${process.env.VUE_APP_API_URL}/broadcasting/auth`, {
                            socket_id: socketId,
                            channel_name: channel.name,
                        })
                            .then((response) => {
                                callback(false, response.data);
                            })
                            .catch((error) => {
                                callback(true, error);
                            });
                    },
                };
            },
        });

        this.echo.connector.options.auth.headers.Authorization = (() => {
            const token = localStorage.getItem("impersonation_token") ?? localStorage.getItem("access_token");

            return token ? `Bearer ${token}` : "";
        })();
    },
};
